<template>
    <div class="footcontainer">
      <div class="foot">
        <div class="connect">
          <p>客服热线</p>
          <h1>400-163-3686</h1>
          <p>服务时间：周一到周六 早9：30- 18：30</p>
          <div class="us">
            <p @click.stop="goconnect">联系我们</p>
            <p @click.stop="goabout">关于我们</p>
            <p @click.stop="goagreement">用户协议</p>
          </div>
          <p class="companyReference">杭州河象网络科技有限公司 (ICP 浙B2-20181300)</p>
        </div>
        <div class="line"></div>
        <div class="service">
          <p>微信服务号</p>
          <img class="code" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/serviceCode.png"/>
        </div>
        <div class="public">
          <p>微信公众号</p>
          <img class="code" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/publicCode.png"/>
        </div>
    </div>
    </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //告诉首页去掉下划线样式
    goconnect() {
      this.$router.push({ path: "contact" });
      this.$emit("underline");
    },
    goabout() {
      this.$router.push({ path: "about" });
      this.$emit("underline");
    },
    goagreement() {
      this.$router.push({ path: "agreement" });
      this.$emit("underline");
    }
  }
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.footcontainer {
  width: 100%;
  height: 386px;
  color: white;
  font-family: MicrosoftYaHei;
  background-color: #333333;
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.us p {
  cursor: pointer;
}
.foot {
  width: 996px;
  height: 386px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.connect {
  width: 368px;
  height: 198px;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.connect p {
  font-size: 20px;
}
.connect h1 {
  font-size: 52px;
}
.us {
  font-size: 20px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  width: 368px;
}
.service {
  width: 192px;
  height: 232px;
}
.code {
  margin-top: 20px;
  width: 192px;
  height: 192px;
}
.line {
  width: 1px;
  height: 285px;
  background-color: white;
}
.public {
  width: 192px;
  height: 232px;
}

</style>
